import { useState, useEffect, useContext } from 'react';
import { useNavigate ,useParams} from 'react-router-dom'; 
import axios from 'axios';
import './updateform.css';
import Context from "../../user_details";
import delete_icon from '../../assets/delete_icon.svg'; // Import your delete icon

function UpdateForm() {
    const { formId } = useParams();
    const [selectedData, setSelectedData] = useState({
        name: "",
        fields: [
            {
                field_name: "",
                is_required: false
            }
        ],
        client_id: ""
    });
    const [firms, setFirms] = useState([]);
    const [errorMessage, setErrorMessage] = useState(""); // Single error message
    const [successMessage, setSuccessMessage] = useState(""); // Success message
    const context = useContext(Context);
    const navigate = useNavigate(); 
    

  // Fetch form data when formId changes
  useEffect(() => {
    if (formId) {
        fetchFormData(formId);
    }
}, [formId]);

const fetchFormData = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/form/read/${id}`);
        //setCreateData(response.data); // Set the fetched form data
        setSelectedData({
            ...response.data,
            client_id: response.data.client_id || "" // Ensure the client_id is set
        });
         // Optionally, set the client_id to be the selected firm
         setFirms(prevFirms => [...prevFirms]); // This line ensures that the firms are available
    } catch (error) {
        console.error("Error fetching form data:", error);
        setErrorMessage("Failed to fetch form data. Please try again.");
    }
};

// useEffect(() => {
//     if (formId) {
//         fetchFormData(formId);
//         if (context.user.user_type === 'superAdmin') {
//             getFirms(); // Ensure firms are fetched when a superadmin is editing
//         }
//     }
// }, [formId, context.user.user_type]);

//     const getFirms = async () => {
//         try {
//             const res = await fetch(`${process.env.REACT_APP_API_URL}/api/firms/list`);
//             const result = await res.json();
//             setFirms(result.results);
//         } catch (error) {
//             console.error("Error fetching firms:", error);
//         }
//     };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSelectedData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFieldNameChange = (event, index) => {
        const { value } = event.target;
        const newFields = selectedData.fields.map((field, i) => 
            i === index ? { ...field, field_name: value } : field
        );
        setSelectedData(prevData => ({
            ...prevData,
            fields: newFields
        }));

        // Immediate validation on field change
        if (!value) {
            setErrorMessage(`Field ${index + 1} is required.`);
        } else {
            // Clear error if the field is filled
            setErrorMessage("");
        }
    };

    const toggleRequired = (index) => {
        const newFields = selectedData.fields.map((field, i) => 
            i === index ? { ...field, is_required: !field.is_required } : field
        );
        setSelectedData(prevData => ({
            ...prevData,
            fields: newFields
        }));
    };

    const addField = () => {
        setSelectedData(prevData => ({
            ...prevData,
            fields: [...prevData.fields, { field_name: "", is_required: false }]
        }));
        setErrorMessage(""); // Clear error message when adding a new field
    };

    const deleteField = (index) => {
        const newFields = selectedData.fields.filter((_, i) => i !== index);
        setSelectedData(prevData => ({
            ...prevData,
            fields: newFields
        }));
        setErrorMessage(""); // Clear error message when deleting a field
    };

    const update_form = async (event,formId) => {
        event.preventDefault();
        
        let error = ""; // Variable for single error message
        setErrorMessage(""); // Clear previous error messages
        setSuccessMessage(""); // Clear previous success message

        if (!selectedData.client_id) {
            error = "Please Select the Firms.";
        } else if (!selectedData.name) {
            error = "Please Enter the Name.";
        } else {
            // Check each field for an empty field name
            for (let i = 0; i < selectedData.fields.length; i++) {
                if (!selectedData.fields[i].field_name) {
                    error = "Please Enter the Field Name";
                    break; // Stop checking after the first empty field
                }
            }
        }

        // If there's an error, set the error message and return
        if (error) {
            setErrorMessage(error);
            setSuccessMessage(""); // Clear success message if there is an error
            return;
        }

        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/form/update/${formId}`, {
                name: selectedData.name,
                fields: selectedData.fields,
                client_id: selectedData.client_id
            });
            console.log("Update Form Data:", response.data);
            setSelectedData({ name: '', fields: [{ field_name: '', is_required: false }], client_id: "" }); // Reset state
            setSuccessMessage("Form Updated successfully!"); // Set success message
            setErrorMessage(""); // Clear error message if creation is successful

            setTimeout(() => {
                navigate('/Forms');
            }, 2000); // 2000ms = 2 seconds

        } catch (error) {
            console.error("Form Update failed:", error);
            setErrorMessage("Form Update failed. Please try again."); // Set error if creation fails
            setSuccessMessage(""); // Clear success message if there is an error
        }
    };
    const handleClose = () => {
        navigate('/Forms'); 
    };

    return (
        <Context.Consumer>
            {value => {
                const { user } = value;
                return (
                    <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
                        <div className='d-flex flex-column align-items-center form_container p-5'>
                            <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                                <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem" alt="logo" />
                            </div>
                            {context.user.user_type === 'superAdmin' && (
                                <div className="d-flex input_container">
                                    <span className='form_label'>Firm Name</span>
                                    <select className="input_element form_input_element p-1" name="client_id"
                                    value={selectedData.client_id}
                                     onChange={handleInputChange}
                                     disabled>
                                        <option value={selectedData.client_id} className='text-secondary'>{selectedData.client_id.firm_name}</option>

                                    </select>
                                </div>
                            )}
                            
                            <div className="d-flex input_container">
                                <span className='form_label'>NAME</span>
                                <input className="input_element form_input_element" 
                                       type="text" 
                                       name="name" 
                                       onChange={handleInputChange} 
                                       value={selectedData.name} 
                                />
                            </div>

                            {/* Display error message */}
                            {errorMessage && (
                                <div className="error_message">
                                    {errorMessage}
                                </div>
                            )}

                            {/* Display success message */}
                            {successMessage && (
                                <div className="success_message">
                                    {successMessage}
                                </div>
                            )}

                            {/* Single table for all fields */}
                            <table className="fields_table mt-4">
                                <tbody>
                                    {selectedData.fields.map((field, index) => (
                                        <tr key={index}>                                            
                                            <td>
                                                Field Name 
                                                <input className="input_element form_input_element"
                                                    type="text"
                                                    value={field.field_name}
                                                    onChange={(event) => handleFieldNameChange(event, index)}
                                                />
                                            </td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={field.is_required}
                                                        onChange={() => toggleRequired(index)}
                                                    />
                                                    Required
                                                </label>
                                            </td>
                                            <td>
                                                <button 
                                                    type="button" 
                                                    onClick={() => deleteField(index)} 
                                                    className="delete_field_button"
                                                    aria-label="Delete Field"
                                                >
                                                   <div className="btn btn-danger" value={field?.user?._id} id={field?.user?._id} >
                                                                    <img  className="delete_icon" src={delete_icon} height="20" />
                                                                </div>
                                                 </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button type="button" className="user_create_button mt-4" onClick={addField}>Add Another Field</button>   
                            <input type="button" className="user_create_button mt-4" value="Update Form" onClick={(event) => update_form(event, formId)} />
                            <button type="button" className="user_create_button mt-4" onClick={handleClose}>Cancel</button>
                        </div>
                        <div className='d-none' id="success_message_user_create">Form Updated Successfully!</div>
                        <div className='d-none' id="fail_message_user_create">Form Updated Failed!</div>
                    </div>
                );
            }}
        </Context.Consumer>
    );
}

export default UpdateForm;
