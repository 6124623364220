import { useState, useEffect, useContext } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom'; 
import axios from 'axios';
import './createform.css';
import Context from "../../user_details";
import delete_icon from '../../assets/delete_icon.svg'; // Import your delete icon

 export default function CreateForm(props) {
    const [createData, setCreateData] = useState({
        name: "",
        fields: [
            {
                field_name: "",
                is_required: false
            }
        ],
        client_id: ""
    });
    const [firms, setFirms] = useState([]);
    const [errorMessage, setErrorMessage] = useState(""); // Single error message
    const [successMessage, setSuccessMessage] = useState(""); // Success message
    const context = useContext(Context);
    const navigate = useNavigate(); 



    useEffect(() => {
        if (context.user.user_type === 'superAdmin') {
            getFirms();
        }
    }, [context.user.user_type]);

    const getFirms = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_API_URL + "/api/firms/list");
            const result = await res.json();
            setFirms(result.results);
        } catch (error) {
            console.error("Error fetching firms:", error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCreateData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFieldNameChange = (event, index) => {
        const { value } = event.target;
        const newFields = createData.fields.map((field, i) => 
            i === index ? { ...field, field_name: value } : field
        );
        setCreateData(prevData => ({
            ...prevData,
            fields: newFields
        }));

        // Immediate validation on field change
        if (!value) {
            setErrorMessage(`Field ${index + 1} is required.`);
        } else {
            // Clear error if the field is filled
            setErrorMessage("");
        }
    };

    const toggleRequired = (index) => {
        const newFields = createData.fields.map((field, i) => 
            i === index ? { ...field, is_required: !field.is_required } : field
        );
        setCreateData(prevData => ({
            ...prevData,
            fields: newFields
        }));
    };

    const addField = () => {
        setCreateData(prevData => ({
            ...prevData,
            fields: [...prevData.fields, { field_name: "", is_required: false }]
        }));
        setErrorMessage(""); // Clear error message when adding a new field
    };

    const deleteField = (index) => {
        const newFields = createData.fields.filter((_, i) => i !== index);
        setCreateData(prevData => ({
            ...prevData,
            fields: newFields
        }));
        setErrorMessage(""); // Clear error message when deleting a field
    };

    const create_profile = async (event) => {
        event.preventDefault();
        
        let error = ""; // Variable for single error message
        setErrorMessage(""); // Clear previous error messages
        setSuccessMessage(""); // Clear previous success message

        if (!createData.client_id) {
            error = "Please Select the Firms.";
        } else if (!createData.name) {
            error = "Please Enter the Name.";
        } else {
            // Check each field for an empty field name
            for (let i = 0; i < createData.fields.length; i++) {
                if (!createData.fields[i].field_name) {
                    error = "Please Enter the Field Name";
                    break; // Stop checking after the first empty field
                }
            }
        }

        // If there's an error, set the error message and return
        if (error) {
            setErrorMessage(error);
            setSuccessMessage(""); // Clear success message if there is an error
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/form/create`, {
                name: createData.name,
                fields: createData.fields,
                client_id: createData.client_id
            });
            console.log("New Form Data:", response.data);
            setCreateData({ name: '', fields: [{ field_name: '', is_required: false }], client_id: "" }); // Reset state
            setSuccessMessage("Form created successfully!"); // Set success message
            setErrorMessage(""); // Clear error message if creation is successful

            setTimeout(() => {
                navigate('/Forms');
            }, 2000); // 2000ms = 2 seconds
            
        } catch (error) {
            console.error("Form creation failed:", error);
            setErrorMessage("Form creation failed. Please try again."); // Set error if creation fails
            setSuccessMessage(""); // Clear success message if there is an error
        }
    };
    const handleClose = () => {
        navigate('/Forms'); 
    };

    return (
        <Context.Consumer>
            {value => {
                const { user } = value;
                return (
                    <div className="profile_create_container d-flex flex-column justify-content-center align-items-center w-100">
                        <div className='d-flex flex-column align-items-center form_container p-5'>
                            <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                                <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem" alt="logo" />
                            </div>
                            {context.user.user_type === 'superAdmin' && (
                                <div className="d-flex input_container">
                                    <span className='form_label'>Firm Name</span>
                                    <select className="input_element form_input_element p-1" name="client_id" onChange={handleInputChange}>
                                        <option value="" className='text-secondary'>---Select a Firm---</option>
                                        {firms.map((firm, index) => (
                                            <option value={firm._id} key={index}>{firm.firm_name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            
                            <div className="d-flex input_container">
                                <span className='form_label'>NAME</span>
                                <input className="input_element form_input_element" 
                                       type="text" 
                                       name="name" 
                                       onChange={handleInputChange} 
                                       value={createData.name} 
                                />
                            </div>

                            {/* Display error message */}
                            {errorMessage && (
                                <div className="error_message">
                                    {errorMessage}
                                </div>
                            )}

                            {/* Display success message */}
                            {successMessage && (
                                <div className="success_message">
                                    {successMessage}
                                </div>
                            )}

                            {/* Single table for all fields */}
                            <table className="fields_table mt-4">
                                <tbody>
                                    {createData.fields.map((field, index) => (
                                        <tr key={index}>                                            
                                            <td>
                                                Field Name 
                                                <input className="input_element form_input_element"
                                                    type="text"
                                                    value={field.field_name}
                                                    onChange={(event) => handleFieldNameChange(event, index)}
                                                />
                                            </td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={field.is_required}
                                                        onChange={() => toggleRequired(index)}
                                                    />
                                                    Required
                                                </label>
                                            </td>
                                            <td>
                                                <button 
                                                    type="button" 
                                                    onClick={() => deleteField(index)} 
                                                    className="delete_field_button"
                                                    aria-label="Delete Field"
                                                >
                                                   <div className="btn btn-danger" value={field?.user?._id} id={field?.user?._id} >
                                                                    <img  className="delete_icon" src={delete_icon} height="20" />
                                                                </div>
                                                 </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button type="button" className="user_create_button mt-4" onClick={addField}>Add Another Field</button>   
                            <input type="button" className="user_create_button mt-4" value="Create Form" onClick={create_profile} />
                            <button type="button" className="user_create_button mt-4" onClick={handleClose}>Cancel</button>
                        </div>
                        <div className='d-none' id="success_message_user_create">Form Created Successfully!</div>
                        <div className='d-none' id="fail_message_user_create">Form Creation Failed!</div>
                    </div>
                );
            }}
        </Context.Consumer>
    );
}

